// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.terms_container{
    width: 100%;
    height: -moz-fit-content;
    height: fit-content;
    background-color: #fff;
    display: flex;
    justify-content: center;
}

.termsPage{
    margin-top: 2rem;
    width: 60%;
    height: -moz-fit-content;
    height: fit-content;
}

.termsPage h1{
    font-size: 24px;
    color: #222;
    font-weight: 500;
    margin: 0;
    margin-bottom: 1rem;
}
.termsPage h2{
    font-size: 20px;
    color: #222;
    font-weight: 500;
    margin: 0;
    margin-bottom: 1rem;
}

.termsPage p{
    color: #444;
    font-size: 16px;
    font-weight: 400;
}

@media screen and (max-width:768px) {
    .termsPage{
        width: 90%;
    }   
}`, "",{"version":3,"sources":["webpack://./src/components/TermsAndCondition/TermsNCondition.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,wBAAmB;IAAnB,mBAAmB;IACnB,sBAAsB;IACtB,aAAa;IACb,uBAAuB;AAC3B;;AAEA;IACI,gBAAgB;IAChB,UAAU;IACV,wBAAmB;IAAnB,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,WAAW;IACX,gBAAgB;IAChB,SAAS;IACT,mBAAmB;AACvB;AACA;IACI,eAAe;IACf,WAAW;IACX,gBAAgB;IAChB,SAAS;IACT,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI;QACI,UAAU;IACd;AACJ","sourcesContent":[".terms_container{\n    width: 100%;\n    height: fit-content;\n    background-color: #fff;\n    display: flex;\n    justify-content: center;\n}\n\n.termsPage{\n    margin-top: 2rem;\n    width: 60%;\n    height: fit-content;\n}\n\n.termsPage h1{\n    font-size: 24px;\n    color: #222;\n    font-weight: 500;\n    margin: 0;\n    margin-bottom: 1rem;\n}\n.termsPage h2{\n    font-size: 20px;\n    color: #222;\n    font-weight: 500;\n    margin: 0;\n    margin-bottom: 1rem;\n}\n\n.termsPage p{\n    color: #444;\n    font-size: 16px;\n    font-weight: 400;\n}\n\n@media screen and (max-width:768px) {\n    .termsPage{\n        width: 90%;\n    }   \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
