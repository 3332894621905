import React, { Suspense, useState, useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Loader from "./Utils/Loader";
import Layout from "./components/Layout/Layout";
import "./App.css";
import Navbar2 from "./components/Navbar/Navbar2";

import Navbar from "./components/Navbar/Navbar"
import Home from "./pages/Home/Home"
import Trade from "./pages/Trade/Trade"
import Earn from "./pages/Earn/Earn"
import Dao from "./pages/DAO/Dao"
import Migration from "./pages/Migration/Migration"


function App() {

  return (
    <>
      <Suspense>
          <div className="hom_wrraper">
            <Routes>
              <Route path='' element={<Layout />}>
                <Route path='/' element={<Navigate to={'/home'} />} />
                <Route path={"/"} element={<Home />} />
                <Route path={"/home"} element={<Home />} />
                <Route path={"/trade"} element={<Trade />} />
                <Route path={"/earn"} element={<Earn />} />
                <Route path={"/dao"} element={<Dao />} />
                <Route path={"/migration"} element={<Migration />} />
              </Route>
            </Routes>
          </div>
      </Suspense>
    </>
  );
}

export default App;




