import React from 'react'
import './Footer.css'
import magnumLogo from '../../assests/logo.webp'
import { Link, useNavigate } from 'react-router-dom'
import { RiTwitterXLine } from 'react-icons/ri'
import { BiLogoFacebook } from 'react-icons/bi'
import { FaTelegramPlane } from 'react-icons/fa'
import { BsMedium } from 'react-icons/bs'
import { ButtonWrapper } from '../Buttons'
import { NavLink } from 'react-router-dom';
import { FaInstagramSquare } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";


// import { LazyLoadImage } from 'react-lazy-load-image-component';
// import 'react-lazy-load-image-component/src/effects/blur.css';


export default function Footer() {

    const navigate = useNavigate()
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };
    return (
        <>
            <div className='footer_main mt-5'>
                <div className="container">
                    <div className="footer_nav flex-column flex-sm-row">
                        <Link to="/" onClick={scrollToTop}>
                            <img src={magnumLogo} alt="magnum" loading='lazy' width={200} />
                        </Link>
                        <div className="ftr_nav_items flex-column flex-sm-row" onClick={scrollToTop}>
                            <NavLink to="/trade" className="nav-link" activeClassName="active-link">Trade</NavLink>
                            <NavLink to="/earn" className="nav-link" activeClassName="active-link">Earn</NavLink>
                            <NavLink to="/dao" className="nav-link" activeClassName="active-link">DAO</NavLink>
                            <NavLink to="/migration" className="nav-link" activeClassName="active-link">Migration</NavLink>
                        </div>
                        <div className='footer-icons'>
                            <span className='footernav_items'>
                                <a target='_blank' rel="noopener noreferrer" href=" https://www.facebook.com/MagnumMetaofficial?_rdc=1&_rdr">
                                    <BiLogoFacebook className='footet-social-icons' fontSize={'30px'} />
                                </a>
                            </span>
                            <span className='footernav_items'>
                                <a target='_blank' rel="noopener noreferrer" href="https://t.me/magnummeta">
                                    <FaTelegramPlane className='footet-social-icons' fontSize={'30px'} />
                                </a>
                            </span>
                            <span className='footernav_items'>
                                <a target='_blank' rel="noopener noreferrer" href="https://ng.linkedin.com/company/magnummeta">
                                    <FaLinkedin className='footet-social-icons' fontSize={'30px'} />
                                </a>
                            </span>
                            <span className='footernav_items'>
                                <a target='_blank' rel="noopener noreferrer" href="https://www.instagram.com/magnummetaofficial/">
                                    <FaInstagramSquare className='footet-social-icons' fontSize={'30px'} />
                                </a>
                            </span>
                            <span className='footernav_items'>
                                <a target='_blank' rel="noopener noreferrer" href="https://x.com/MagnumMeta">
                                    <RiTwitterXLine className='footet-social-icons' fontSize={'30px'} />
                                </a>
                            </span>
                            <span className='footernav_items meduimIocnbox'>
                                <a target='_blank' rel="noopener noreferrer" href="https://medium.com/@magnummeta">
                                    <BsMedium className='footet-social-icons' color='#fff' fontSize={'15px'} />
                                </a>
                            </span>
                        </div>
                    </div>

                    <div className='row d-flex flex-sm-row flex-column-reverse'>
                        <div className='feed-back-container col-md-6 mt-5'>
                            <p className='align-baseline copyright-para'>© Copyright {new Date().getFullYear()}. All rights reserved <br />
                                <span className='terms-para'>
                                    <span onClick={() => navigate('/home/TermsandCondtion')}>Terms & Conditions</span> |
                                    <a className='hreftext' target='_blank' rel="noopener noreferrer" href="https://magnummeta.gitbook.io/docs">
                                        {' '}White Paper{' '}
                                    </a>
                                    {/* <span onClick={() => navigate('/home/yieldFarming')}>Yield Farming</span> */}
                                </span>
                            </p>
                        </div>
                        <div className="feedback-box col-md-6 mt-5">
                            <h1 className='feedback-title-text'>Subscribe to our newsletter</h1>
                            <div className="ftr-fdbk-ipt-bx">
                                <div>
                                    <input type="email"
                                        placeholder='Your Email'
                                    />
                                </div>
                                <ButtonWrapper style={{ width: '160px' }}>Submit</ButtonWrapper>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
