import React, { useEffect } from 'react'
import './TermsNCondition.css'

export default function TermsNCondition() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <div className="terms_container">
                <div className="termsPage">
                    <h1>Terms of Use</h1>
                    <h1>1. Introduction and Acceptance of Terms</h1>
                    <p>
                        Welcome to MagnumMeta, your innovative provider of decentralized e-learning solutions utilizing blockchain and AI technology. These Terms of Use ("Terms") govern your access to and use of all services provided by MagnumMeta. By accessing, browsing, or using our platform, you acknowledge that you have read, understood, and agree to be bound by these Terms. If you do not agree with any part of these Terms, you should not use our services.
                    </p>
                    <p>
                        <strong>Acceptance Method:</strong> Continued use of our website indicates your acceptance of these updated Terms. It is your responsibility to review the Terms periodically for changes.
                    </p>
                    <h1>2. Description of Services</h1>
                    <p>
                        <strong>Core Services:</strong> MagnumMeta offers a range of decentralized e-learning solutions that are designed to enhance learning experiences using the latest in blockchain and AI technologies. Our services include but are not limited to:
                    </p>
                    <ul>
                        <li><strong>Decentralized E-Learning Platforms:</strong> Providing users with access to educational content and courses in a decentralized manner, ensuring security and accessibility.</li>
                        <li><strong>AI Learning Management Systems (LMS):</strong> Utilizing artificial intelligence to tailor learning experiences to individual needs, optimizing learning outcomes for all users.</li>
                    </ul>
                    <p>
                        <strong>Expansion and Updates:</strong> Our services are continuously evolving, and we may offer new functionalities or enhancements to existing services. Each service or new feature that is added to the platform will be subject to its own terms and conditions, which will be made available in relevant sections of our platform or as updates to these Terms.
                    </p>
                    <h1>3. User Accounts and Responsibilities</h1>
                    <p>
                        <strong>Account Registration:</strong> Users must register for an account to fully access our services. During registration, you must provide truthful and accurate information and keep this information up to date. It is your responsibility to maintain the confidentiality of your account details, including your password.
                    </p>
                    <h1>Account Registration:</h1>
                    <ul>
                        <li>
                            <strong>Account Security:</strong> You are responsible for all activities that occur under your account. You should immediately notify MagnumMeta of any unauthorized use of your account or breach of security.
                        </li>
                        <li>
                            <strong>Legal Compliance: </strong> You must use the services in compliance with all applicable local, state, national, and international laws, regulations, and policies.
                        </li>
                    </ul>
                    <p>
                        <strong>User Conduct:</strong> You must not misuse our services. Prohibited activities include but are not limited to:
                    </p>
                    <ul>
                        <li>
                            <strong>Fraudulent Behavior:</strong> Engaging in fraudulent activities is strictly prohibited and may result in account termination and legal action.
                        </li>
                        <li>
                            <strong>Interference with Services:</strong> You must not interfere with or disrupt the services or servers or networks connected to the services.
                        </li>
                    </ul>
                    <p>
                        By creating an account, you agree to comply with these responsibilities and ensure that your account activity upholds the integrity and security of our platform.
                    </p>
                    <h1>4. Token Use and Management</h1>
                    <p>
                        <strong>Current Token Use:</strong> MagnumMeta exclusively uses the Metaforge (MFORGE) token within its ecosystem. This token facilitates access to services, participation in governance, and other platform functionalities.
                    </p>
                    <h1>Migration from MagnumBits to Metaforge (MFORGE):</h1>
                    <ul>
                        <li>
                            <strong>Migration Process:</strong> MagnumMeta provides a mechanism for holders of the old MagnumBits (MGB) tokens to convert their holdings to the new MFORGE tokens. We offer a flexible migration period, accommodating holders to convert their tokens at their convenience without a predetermined deadline.
                        </li>
                        <li>
                            <strong>Continuous Support for Migration:</strong> We understand the needs of our token holders vary, and we will continue to support the migration process for an indefinite period. Holders are encouraged to migrate their tokens promptly to take full advantage of the new features and benefits offered by the MFORGE tokens.
                        </li>
                    </ul>
                    <h1>5. Financial Transactions and Staking</h1>
                    <h1>Transactions on the Platform:</h1>
                    <ul>
                        <li>
                            <strong>Service Purchases:</strong> All purchases of services or courses on MagnumMeta are conducted using MFORGE tokens. Transactions are recorded on the blockchain, providing transparency and security for all parties involved.
                        </li>
                        <li>
                            <strong>Staking and Rewards:</strong> Users may participate in staking mechanisms to earn rewards and contribute to the platform’s stability. Detailed terms, including reward mechanisms and staking requirements, are specified within dedicated sections for each staking opportunity.
                        </li>
                    </ul>
                    <h1>Financial Integrity:</h1>
                    <ul>
                        <li>
                            <strong>No Refund Policy:</strong> Due to the nature of digital transactions involving tokens, all transactions on MagnumMeta are final and non-refundable.
                        </li>
                        <li>
                            <strong>Security of Transactions:</strong> We implement advanced security measures to protect the integrity and privacy of your financial transactions. However, users are also responsible for ensuring the security of their wallet and any credentials related to financial transactions.
                        </li>
                    </ul>
                    <h1>6. User Conduct and Prohibited Activities</h1>
                    <h1>Expected Conduct:</h1>
                    <ul>
                        <li>
                            <strong>Respectful Interaction:</strong> Users are expected to interact with the platform and other users respectfully, without engaging in behavior that is disruptive, offensive, or harmful.
                        </li>
                        <li>
                            <strong>Intellectual Property Respect:</strong> Users must respect the intellectual property rights of content creators and the platform. Unauthorized use of material available on MagnumMeta is strictly prohibited.
                        </li>
                    </ul>
                    <h1>Prohibited Activities:</h1>
                    <ul>
                        <li>
                            <strong>No Illegal Use:</strong> The services may not be used for illegal purposes, including but not the limited to, money laundering, terrorist financing, or violating intellectual property laws.
                        </li>
                        <li>
                            <strong>Avoidance of System Manipulation:</strong> Users are prohibited from attempting to manipulate the system, whether through creating multiple accounts, exploiting bugs, or other means designed to undermine the fairness or integrity of the platform.
                        </li>
                    </ul>
                    <p>Violations of the user conduct guidelines or engagement in prohibited activities will result in disciplinary action, up to and including account suspension or termination, legal action, and restitution where applicable.</p>

                    <h1>7. Rights and Obligations of the Platform</h1>
                    <h1>Service Delivery:</h1>
                    <ul>
                        <li>
                            <strong>Service Continuity and Changes: </strong>MagnumMeta commits to providing continuous service but reserves the right to modify, suspend, or discontinue any part of its services with or without notice. This may be necessary due to maintenance, updates, or other operational decisions.
                        </li>
                        <li>
                            <strong>Service Quality:</strong> While MagnumMeta strives to provide high-quality services, the dynamic nature of blockchain and AI technology means that service disruptions and imperfections may occur. We aim to address and rectify such issues promptly.
                        </li>
                    </ul>
                    <h1>Platform Rights:</h1>
                    <ul>
                        <li>
                            <strong>Content and Feature Modification:</strong> MagnumMeta reserves the right to introduce new features, adjust existing services, and update content as needed to enhance service quality or comply with regulatory requirements.
                        </li>
                        <li>
                            <strong>Account Termination:</strong> The platform has the right to terminate or suspend accounts that are found to be in violation of these Terms, involved in illegal activities, or detrimental to the security or reputation of MagnumMeta.
                        </li>
                    </ul>
                    <h1>8. Intellectual Property</h1>
                    <h1>Ownership:</h1>
                    <ul>
                        <li>
                            <strong>Platform Content:</strong> All content on MagnumMeta, including text, graphics, logos, images, as well as the platform’s design, software, and all other intellectual property, are the exclusive property of MagnumMeta or its licensors.
                        </li>
                        <li>
                            <strong>User-Generated Content:</strong> Users may create or upload content while using our services. By doing so, they grant MagnumMeta a non-exclusive, royalty-free license to use, reproduce, modify, and display such content as part of our services.
                        </li>
                    </ul>
                    <h1>Protection and Respect</h1>
                    <ul>
                        <li>
                            <strong>Respecting Rights:</strong> Users agree to respect the intellectual property rights of others, including copyrights and trademarks owned by MagnumMeta and third parties.
                        </li>
                        <li>
                            <strong>Prohibitions:</strong> Users must not copy, reproduce, redistribute, or use any part of the services or content on MagnumMeta without explicit permission from the rightful owners.
                        </li>
                    </ul>
                    <h1>9. Termination and Suspension of Accounts</h1>
                    <h1>Conditions for Termination:</h1>
                    <ul>
                        <li>
                            <strong>Breach of Terms:</strong> Accounts may be suspended or terminated if users violate any terms outlined in this agreement, engage in fraudulent activities, or otherwise harm the platform or its users.
                        </li>
                        <li>
                            <strong>Inactive Accounts:</strong> MagnumMeta reserves the right to terminate or deactivate accounts that are inactive for an extended period as defined by our operational policies.
                        </li>
                    </ul>
                    <h1>Reinstatement Procedure:</h1>
                    <ul>
                        <li>
                            <strong>Appeals:</strong> Users whose accounts have been suspended or terminated may appeal the decision by contacting MagnumMeta support, providing details and evidence to support their case for reinstatement.
                        </li>
                        <li>
                            <strong>Review Process:</strong> Each appeal will be reviewed on a case-by-case basis. MagnumMeta will consider the user's conduct, the nature of the violation, and any other relevant information before making a final decision.
                        </li>
                    </ul>

                    <h1>10. Liability, Indemnities, and Warranties</h1>
                    <h1>Liability Limitations:</h1>
                    <ul>
                        <li>
                            <strong>General Limitations:</strong> MagnumMeta will not be liable for any indirect, incidental, special, consequential, or punitive damages arising out of or related to your use of the platform. This includes, but is not limited to, damages for loss of profits, goodwill, use, data, or other intangible losses, even if MagnumMeta has been advised of the possibility of such damages.
                        </li>
                        <li>
                            <strong>Specific Exemptions:</strong> The platform is not responsible for losses or damages arising from unauthorized access to or alteration of your transmissions or data, any material or data sent or received or not sent or received, or any transactions entered into through our service.
                        </li>
                    </ul>
                    <h1>Indemnification:</h1>
                    <ul>
                        <li>
                            <strong>User Responsibilities: </strong>You agree to indemnify and hold MagnumMeta, its affiliates, officers, agents, employees, and partners harmless from any claim or demand, including reasonable attorneys' fees, made by any third party due to or arising out of your use of the platform, your connection to the platform, your violation of the Terms, or your violation of any rights of another.
                        </li>
                    </ul>
                    <h1>Warranties:</h1>
                    <ul>
                        <li>
                            <strong>Service As Is:</strong> The services provided by MagnumMeta are available on an "as is" and "as available" basis. MagnumMeta does not warrant that the service will be uninterrupted, timely, secure, or error-free.
                        </li>
                        <li>
                            <strong>No Endorsement:</strong> The use of any data or information received through the MagnumMeta platform is at your own discretion and risk. MagnumMeta explicitly disclaims any responsibility for the accuracy, content, or availability of information found on services that link to or from the platform.
                        </li>
                    </ul>
                    <h1>11. Dispute Resolution</h1>
                    <h1>Arbitration Agreement:</h1>
                    <ul>
                        <li>
                            <strong>Mandatory Arbitration:</strong> You agree that any dispute, claim, or controversy arising out of or relating to these Terms or the breach, termination, enforcement, interpretation, or validity thereof, including the determination of the scope or applicability of this agreement to arbitrate, shall be determined by arbitration in the British Virgin Islands. The arbitration will be conducted in English and administered by a mutually agreed-upon arbitrator in accordance with local laws.
                        </li>
                        <li>
                            <strong>Class Action Waiver:</strong> You agree that any arbitration will be conducted in your individual capacity only and not as a class action or other representative action, and you expressly waive your right to file a class action or seek relief on a class basis.
                        </li>
                    </ul>
                    <h1>12. Changes to Terms of Use</h1>
                    <h1>Notification of Changes:</h1>
                    <ul>
                        <li>
                            <strong>Method of Notification:</strong> MagnumMeta reserves the right to modify these Terms at any time. When we make changes, we will post the revised Terms on our website and update the "Last Updated" date at the top of the Terms.
                        </li>
                        <li>
                            <strong>Acceptance of Changes:</strong> Your continued use of the platform after any changes to these Terms constitutes your acceptance of the new Terms.
                        </li>
                    </ul>
                    <h1>13. Miscellaneous</h1>
                    <h1>Severability:</h1>
                    <ul>
                        <li>
                            If any provision of these Terms is found by a court of competent jurisdiction to be invalid, the parties nevertheless agree that the court should endeavor to give effect to the parties' intentions as reflected in the provision, and the other provisions of the Terms remain in full effect.
                        </li>
                    </ul>
                    <h1>Entire Agreement:</h1>
                    <ul>
                        <li>
                            These Terms constitute the entire agreement between you and MagnumMeta regarding the use of the MagnumMeta platform, superseding any prior agreements between you and MagnumMeta regarding the platform.
                        </li>
                    </ul>
                    <h1>Jurisdiction:</h1>
                    <ul>
                        <li>
                            The Terms are governed by and construed in accordance with the laws of the British Virgin Islands, without giving effect to any principles of conflicts of law.
                        </li>
                    </ul>
                    <h1>14. Acceptance of Terms</h1>
                    <p>
                        <strong>Electronic Acceptance: </strong> By using the services provided by MagnumMeta, you are acknowledging electronically that you have read, understood, and agree to be bound by these Terms of Use and any documents incorporated by reference. You agree that your use of the services constitutes an electronic signature to these Terms and that you are legally bound by them just as if you had signed them in ink.
                    </p>
                    <p>
                        <strong>Continued Agreement:</strong> Your continued use of the MagnumMeta platform after any changes to these Terms of Use or additional policies signifies your acceptance of the changes and the updated Terms. If at any time the Terms are no longer acceptable to you, you should immediately cease all use of the services.
                    </p>
                    <p>
                        <strong>Legal Capacity: </strong>You affirm that you are of legal age to enter into this agreement and you are fully able and competent to meet the terms, conditions, and obligations herein. You affirm that you are either more than 18 years of age, or an emancipated minor, or possess legal parental or guardian consent, and are fully able and competent to enter into the terms, conditions, obligations, affirmations, representations, and warranties set forth in these Terms, and to abide by and comply with these Terms
                    </p>
                    <p>
                        <strong>Final Acknowledgment:</strong> These Terms of Use, including any policies or amendments that may be incorporated by reference, constitute the entire agreement between you and MagnumMeta regarding the platform. They supersede all prior agreements or communications between you and MagnumMeta regarding the subject matter hereof. Any additional or different terms in any written communication from you (including any request for service) are void.
                    </p>
                    <p>
                        <strong>Contact Information:</strong> If you have any questions or concerns about these Terms or any issues raised in these Terms or on the MagnumMeta platform, please contact us at:
                    </p>
                    <ul>
                        <li><strong>Email: </strong>info@magnummeta.com</li>
                    </ul>
                </div>
            </div>
        </>
    )
}
