import React, { useEffect } from 'react'

export default function YeildFarming() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <div className="terms_container">
                <div className='termsPage'>
                    <h1>Staking Agreement for MagnumMeta</h1>
                    <h1>Introduction</h1>
                    <p>
                        This Staking Agreement ("Agreement") is entered into by and between the individual or entity participating in staking activities (the "Participant") and MagnumMeta (the "Company"), collectively referred to as the "Parties". This Agreement outlines the terms and conditions under which the Participant may participate in the staking of LP Tokens and MFORGE Tokens within the MagnumMeta ecosystem.
                    </p>
                    <h1>Recitals</h1>
                    <p>
                        <strong> WHEREAS,</strong> MagnumMeta operates a platform that enables the staking of digital assets, specifically LP Tokens and MFORGE Tokens, offering rewards in return for the staked assets;
                    </p>
                    <p>
                        <strong>WHEREAS,</strong> only LP Tokens obtained from the Liquidity Pool created by MagnumMeta are eligible for staking in the MagnumMeta smart contract. If users provide liquidity to the said pool, the LP Tokens they receive can be staked in the smart contract;
                    </p>
                    <p>
                        <strong>WHEREAS,</strong> the Participant desires to engage in staking activities using MagnumMeta’s platform and to receive rewards in accordance with the terms set forth herein;
                    </p>
                    <p>
                        <strong> WHEREAS,</strong> MagnumMeta wishes to establish the terms and conditions governing these staking activities to ensure clarity, governance, and mutual benefit;
                    </p>
                    <p>
                        <strong>NOW, THEREFORE,</strong> in consideration of the mutual covenants and promises herein contained, the Parties hereby agree as follows:
                    </p>
                    <ol>
                        <li>
                            The Participant shall adhere to all terms and conditions specified in this Agreement related to staking activities, reward distribution, and governance participation.
                        </li>
                        <li>
                            MagnumMeta shall provide the necessary platform and infrastructure to facilitate the staking activities as described in this Agreement.
                        </li>
                        <li>
                            Both Parties commit to performing their obligations under this Agreement in good faith and with due diligence.
                        </li>
                    </ol>
                    <h1>Staking Agreement - Section 1: General Provisions and Staking Details</h1>
                    <h1>1. General Provisions</h1>
                    <p>
                        <strong>1.1. Agreement to Terms:</strong> By participating in the staking process, you ("the Participant") agree to the terms outlined in this Staking Agreement ("Agreement"), governed by the laws of the British Virgin Islands (BVI).
                    </p>
                    <p>
                        <strong>1.2. Company Overview: </strong>This Agreement is between you and MagnumMeta ("the Company"), a corporation registered under the laws of the BVI, which operates the staking services outlined herein.
                    </p>
                    <h1>2. Definition of Tokens</h1>
                    <p>
                        <strong>2.1. Staking Tokens:</strong> The tokens eligible for staking under this Agreement are categorized into:
                    </p>
                    <ul>
                        <li>
                            <strong>LP Tokens:</strong> Tokens received when a user provides liquidity in our specified pair on a decentralized exchange.
                        </li>
                        <li>
                            <strong>MFORGE Tokens:</strong> MagnumMeta's proprietary tokens.
                        </li>
                    </ul>
                    <h2>2.2. Purpose of Tokens:</h2>
                    <ul>
                        <li><strong>LP Tokens:</strong> Used primarily to facilitate liquidity in decentralized exchanges.</li>
                        <li><strong>MFORGE Tokens:</strong> Serve as a utility token within the MagnumMeta ecosystem, providing access to specific functionalities and rewards.</li>
                    </ul>
                    <h1>3. Staking Requirements</h1>
                    <h2>3.1. Minimum and Maximum Staking Amounts:</h2>
                    <ul>
                        <li>There are no minimum or maximum staking limits imposed by this Agreement for either type of tokens.</li>
                    </ul>
                    <h2>3.2. Token Usage:</h2>
                    <ul>
                        <li><strong>LP Tokens:</strong> Must be locked in the designated smart contract of the Company for a user-selected period.</li>
                        <li><strong>MFORGE Tokens:</strong> Can be staked directly through the Company's staking interface without a mandatory lock-in period unless specified otherwise.</li>
                    </ul>
                    <h1>4. Lock Durations and Terms</h1>
                    <h2>4.1. Available Lock Durations:</h2>
                    <ul>
                        <li><strong>LP Tokens:</strong> The lock-in durations available are 3, 5, 8, 12, and 24 months.</li>
                        <li><strong>MFORGE Tokens:</strong> No mandatory lock-in period but Participant is encouraged to maintain their stakes to maximize potential rewards.</li>
                    </ul>
                    <h2>4.2. Early Termination of Lock:</h2>
                    <ul>
                        <li><strong>LP Tokens:</strong> Early withdrawal is not permitted; staked LP tokens must remain locked until the completion of the selected duration.</li>
                        <li><strong>MFORGE Tokens:</strong> Participant may withdraw at any time; however, an early withdrawal could result in reduced rewards or penalties as outlined in subsequent sections of this Agreement.</li>
                    </ul>
                    <h1>5. Rewards and Yield Calculations</h1>
                    <h2>5.1. Annual Percentage Yields (APRs):</h2>
                    <ul>
                        <li>LP Tokens: The APRs vary by the lock-in period as follows:</li>
                        <ul style={{ listStyleType: 'disc' }}>
                            <li>3 months: 55%</li>
                            <li>5 months: 70%</li>
                            <li>8 months: 85%</li>
                            <li>12 months: 115%</li>
                            <li>24 months: 170%</li>
                        </ul>
                        <li><strong>MFORGE Tokens:</strong> A fixed APR of 30% regardless of the staking duration.</li>
                    </ul>
                    <h1>5.2. Reward Distribution Frequency:</h1>
                    <ul>
                        <li>Rewards are distributed daily to the Participant's designated wallet for both LP and MFORGE token staking.</li>
                    </ul>
                    <h1>6. Reward Withdrawal</h1>
                    <h2>6.1. Withdrawal Schedule:</h2>
                    <ul>
                        <li><strong>LP Tokens:</strong> Rewards can be withdrawn up to twice each month on pre-specified dates.</li>
                        <li><strong>MFORGE Tokens:</strong> No specific withdrawal restrictions; rewards can be accessed as they are accrued.</li>
                    </ul>
                    <h1>6.2. Penalties for Unscheduled Withdrawals:</h1>
                    <ul>
                        <li><strong>LP Tokens:</strong> Unscheduled withdrawals are subject to a 20% penalty if exceeding the twice-per-month limit.</li>
                        <li><strong>MFORDGE Tokens:</strong> No penalties for unscheduled withdrawals but early un-staking penalties may apply as detailed in subsequent sections.</li>
                    </ul>
                    <h1>Staking Agreement - Section 2: Reward Mechanisms and Penalties</h1>
                    <h1>7. Reward Adjustment Mechanism</h1>
                    <h2>7.1. APR Adjustment Triggers:</h2>
                    <ul>
                        <li><strong>LP Tokens:</strong> The APR rates are adjusted downwards by 50% whenever the cumulative rewards disbursed reach the threshold of 3,000,000 MFORGE tokens. This mechanism ensures sustainability and long-term viability of the staking program.</li>
                    </ul>
                    <h2>7.2. Monitoring and Notification:</h2>
                    <ul>
                        <li>The Company will monitor the distribution of rewards and notify all Participants of impending APR adjustments through email and on the staking platform’s dashboard.</li>
                    </ul>
                    <h1>8. Withdrawal Penalties</h1>
                    <h2>8.1. Scheduled and Unscheduled Withdrawals:</h2>
                    <ul>
                        <li>
                            <strong>LP Tokens:</strong> Withdrawals are strictly scheduled twice per month. Any withdrawal outside these times will incur a 20% penalty on the withdrawn amount.
                        </li>
                        <li>
                            <strong>MFORGE Tokens:</strong> There are no penalties for withdrawing rewards at any time; however, the principal's early withdrawal carries specific penalties.
                        </li>
                    </ul>
                    <h2>8.2. Penalties for Early Un-staking of Principal:</h2>
                    <ul>
                        <li>If MFORGE tokens are un-staked before the end of the lock-in period (if any), the penalties are as follows:</li>
                        <ul>
                            <li>Less than 1 week: No rewards distributed</li>
                            <li>1 to 2 weeks: 50% of accrued rewards distributed.</li>
                            <li>2 to 3 weeks: 70% of accrued rewards distributed.</li>
                            <li>Over 4 weeks: 100% of accrued rewards distributed.</li>
                        </ul>
                    </ul>
                    <h1>9. Re-staking and Continuous Participation Incentives</h1>
                    <h2>9.1. Re-staking Options:</h2>
                    <ul>
                        <li>After the lock duration ends, users may opt to re-stake their tokens. This process is encouraged by offering incremental APR bonuses for successive staking periods.</li>
                    </ul>
                    <h2>9.2. Incentives for Continuous Staking:</h2>
                    <ul>
                        <li>Users who continuously stake their tokens without withdrawing between periods may receive additional bonuses, enhancing the APR by up to 10% depending on the continuity and amount of the stake.</li>
                    </ul>
                    <h1>10. Early Unstaking Penalties</h1>
                    <h2>10.1. Penalties for Early Unstaking:</h2>
                    <ul>
                        <li><strong>LP Tokens:</strong> Unstaking before the chosen period ends is not allowed. Any attempt to do so will lead to the forfeiture of all accrued rewards.</li>
                        <li><strong>MFORGE Tokens:</strong> As detailed in Section 8, penalties vary based on the unstaking time frame.</li>
                    </ul>
                    <h2>10.2. Exceptional Circumstances:</h2>
                    <ul>
                        <li>In exceptional circumstances, such as critical financial hardships, the Company may waive penalties for early unstaking at its discretion. Participants must apply for such consideration through a formal process, providing relevant documentation to support their claims.</li>
                    </ul>
                    <h1>11. Reward Calculation and Distribution</h1>
                    <h2>11.1. Calculation of Rewards:</h2>
                    <ul>
                        <li>Rewards are calculated daily based on the current APR and the amount of tokens staked. Adjustments to the reward calculation due to APR changes are implemented immediately following any trigger event.</li>
                    </ul>
                    <h2>11.2. Distribution of Rewards:</h2>
                    <ul>
                        <li>Rewards are distributed directly to the Participant’s wallet and can be tracked via the user dashboard on the staking platform. All distributions are subject to the terms regarding withdrawal frequencies and penalties.</li>
                    </ul>
                    <h1>Staking Agreement - Section 3: Governance, Voting, and User Responsibilities</h1>
                    <h1>12. Governance Participation</h1>
                    <h2>12.1. Eligibility for Governance:</h2>
                    <ul>
                        <li>Any Participant with an active MFORGE token stake for a minimum of three months is eligible to participate in governance decisions affecting the MagnumMeta ecosystem.</li>
                    </ul>
                    <h1>Governance Rights:</h1>
                    <ul>
                        <li>Eligible Participants can vote on various proposals, including but not limited to the use of tokens acquired in buybacks, partnership decisions, development directions, use of treasury funds, and token burn initiatives.</li>
                    </ul>
                    <h1>13. Staking for Proposals</h1>
                    <h2>13.1. Proposal Submission Requirements:</h2>
                    <ul>
                        <li>Participants wishing to submit governance proposals must maintain an active staking position and must have staked for at least three consecutive months prior to the proposal submission.</li>
                    </ul>
                    <h2>13.2. Support for Proposals:</h2>
                    <ul>
                        <li>Proposals that receive sufficient support from the staking community, measured by a majority of votes cast, will be considered for implementation. The specific threshold for "sufficient support" is defined as more than 50% of the votes cast in the governance poll.</li>
                    </ul>
                    <h1>14. Technical Requirements and Security</h1>
                    <h2>14.1. Compatible Wallets and Platforms:</h2>
                    <ul>
                        <li>Participants must use compatible wallets that support the blockchain technology utilized by MagnumMeta. A list of approved wallets is available on the Company’s website.</li>
                    </ul>
                    <h2>14.2. Security Measures:</h2>
                    <ul>
                        <li>Participants are responsible for maintaining the security of their private keys and wallet credentials. The Company is not responsible for losses due to compromised wallet security.</li>
                    </ul>
                    <h1>15. User Responsibilities</h1>
                    <h2>15.1. Compliance with Terms:</h2>
                    <ul>
                        <li>Participants must adhere to all terms set forth in this Agreement, including lock periods, withdrawal schedules, and governance participation rules.</li>
                    </ul>
                    <h2>15.2. Reporting Obligations:</h2>
                    <ul>
                        <li>Participants are responsible for reporting any rewards received through staking for tax purposes as per their local jurisdiction's laws.</li>
                    </ul>
                    <h2>15.3. Ethical Staking Practices:</h2>
                    <ul>
                        <li>Participants are expected to engage in ethical staking practices, including not manipulating the staking system or participating in activities that could undermine the integrity of the MagnumMeta ecosystem.</li>
                    </ul>
                    <h1>16. Dispute Resolution</h1>
                    <h2>16.1. Informal Dispute Resolution:</h2>
                    <ul>
                        <li>Parties should attempt to resolve any disputes informally via good faith negotiations. Participants should contact MagnumMeta at the provided contact details to initiate dispute resolution.</li>
                    </ul>
                    <h1>16.2. Formal Proceedings:</h1>
                    <ul>
                        <li>If informal resolution fails, disputes will be resolved through arbitration in accordance with the Arbitration Rules of the British Virgin Islands. The arbitration will take place in the BVI, and the decision of the arbitrator will be final and binding.</li>
                    </ul>
                    <h1>17. Compliance and Legal Obligations</h1>
                    <h2>17.1. Legal Compliance:</h2>
                    <ul>
                        <li>Participants must ensure that their participation in staking complies with applicable laws and regulations in their jurisdiction, including but not limited to those relating to financial services and anti-money laundering.</li>
                    </ul>
                    <h2>17.2. Tax Obligations:</h2>
                    <ul>
                        <li>It is the responsibility of the Participant to determine the tax implications of their staking activities and to comply with all relevant tax requirements, including reporting and payment of any taxes due.</li>
                    </ul>
                    <h1 className='stakingAgree'>Staking Agreement - Section 4: Risk Acknowledgment, Legal Disclaimers, and Final Provisions</h1>
                    <h1>18. Assumption of Risk</h1>
                    <h2>18.1. Market Volatility:</h2>
                    <ul>
                        <li>Participants acknowledge the inherent volatility of the cryptocurrency market, which may affect the value of staked tokens and rewards. Such volatility is outside the control of MagnumMeta.</li>
                    </ul>
                    <h2>18.2. Technological Risks:</h2>
                    <ul>
                        <li>Participants accept the risks associated with using blockchain technology, including, but not limited to, errors, bugs, or vulnerabilities in the software or hardware used to facilitate staking.</li>
                    </ul>
                    <h2>18.3. Regulatory Changes:</h2>
                    <ul>
                        <li>Changes in regulations or law may impact the terms of staking, and Participants agree to comply with any such changes as they occur.</li>
                    </ul>
                    <h1>19. Legal Disclaimers</h1>
                    <h2>19.1. No Guarantees on Returns:</h2>
                    <ul>
                        <li>MagnumMeta does not guarantee any specific returns from staking. Participants should understand that past performance is not indicative of future results.</li>
                    </ul>
                    <h2>19.2. Liability Limitations:</h2>
                    <ul>
                        <li>MagnumMeta is not liable for any losses that Participants may incur due to market conditions, technological issues, or regulatory changes. Participants agree to not hold MagnumMeta liable for any such losses.</li>
                    </ul>
                    <h2>19.3. Smart Contract Reliability:</h2>
                    <ul>
                        <li>While MagnumMeta takes measures to ensure the reliability of its smart contracts, it cannot guarantee that they will be free from errors or disruptions. Participants acknowledge this risk and agree to bear it.</li>
                    </ul>
                    <h1>20. Final Provisions</h1>
                    <h2>20.1. Amendment of Agreement:</h2>
                    <ul>
                        <li>MagnumMeta reserves the right to amend the terms of this Agreement at any time. Any amendments will be communicated to Participants through the official channels and will take effect immediately upon announcement.</li>
                    </ul>
                    <h2>20.2. Entire Agreement:</h2>
                    <ul>
                        <li>This Agreement constitutes the entire agreement between the Participant and MagnumMeta regarding the staking process. It supersedes all prior agreements and understandings, whether written or oral, concerning its subject matter.</li>
                    </ul>
                    <h2>20.3. Severability:</h2>
                    <ul>
                        <li>If any part of this Agreement is found to be invalid or unenforceable, the remaining parts will continue to be valid and enforceable. The invalid or unenforceable part will be replaced with a valid provision that most closely matches the intent of the original.</li>
                    </ul>
                    <h2>20.4. Jurisdiction:</h2>
                    <ul>
                        <li>This Agreement is governed by the laws of the British Virgin Islands. Any legal actions or proceedings related to this Agreement shall be exclusively subject to the jurisdiction of the courts of the BVI.</li>
                    </ul>
                    <h1>21. Acceptance of Terms</h1>
                    <h2>21.1. Agreement to Terms:</h2>
                    <ul>
                        <li>By participating in staking, Participants affirm their acceptance of this Agreement and all its terms. Continued participation in staking constitutes ongoing agreement to any amendments made to these terms.</li>
                    </ul>
                    <h2>21.2. Electronic Signature:</h2>
                    <ul>
                        <li>An electronic signature or any affirmative action taken on the MagnumMeta platform regarding staking constitutes a binding acceptance of this Agreement and any of its amendments.</li>
                    </ul>
                    <h1>Signatures Section</h1>
                    <h1>22. Execution</h1>
                    <h2>22.1. Consent to Electronic Signatures:</h2>
                    <ul>
                        <li>The parties agree that electronic signatures, marks, or affirmative digital actions undertaken on MagnumMeta's staking platform shall constitute valid and binding execution of this Agreement. Each party acknowledges that they are fully informed of the terms and the electronic nature of the signatures.</li>
                    </ul>
                    <h2>22.2. Effective Date:</h2>
                    <ul>
                        <li>This Agreement shall be considered executed and effective as of the date on which the Participant first participates in staking activities on MagnumMeta's platform, as recorded by MagnumMeta's electronic systems.</li>
                    </ul>
                    <h2>22.3. Acknowledgment of Understanding:</h2>
                    <ul>
                        <li>By signing this Agreement electronically, the Participant acknowledges that they have read the terms of the Agreement, understand them, and agree to be bound by them. The Participant represents that they have the legal capacity to enter into this Agreement.</li>
                    </ul>
                </div>
            </div>
        </>
    )
}
